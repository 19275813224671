import React, { useRef, useState } from "react";
import { MegaMenuColumn } from "../MegaMenu/MegaMenu";
import { DesktopNavbarItem } from "./subtypes/DesktopNavbarItem";
import { MobileNavbarItem } from "./subtypes/MobileNavbarItem";
import { MobileOwnMenu } from "./subtypes/MobileOwnMenu";

export enum DesktopAlign {
  Left = "left",
  Right = "right",
}
export enum MobileMenuType {
  Main = "main",
  Own = "own",
  None = "none",
}
export enum ItemTypes {
  Button = "button",
  Dropdown = "dropdown",
  Search = "search",
  MegaMenu = "megaMenu",
}
export enum Placement {
  top = "top",
  bottom = "bottom",
  bottomEnd = "bottom-end",
  bottomStart = "bottom-start",
}
export interface DropdownItem {
  label: string;
  link?: string;
  click?: (event) => void;
  icon?: string;
  isActive?: boolean;
  testSelector?: string;
}
export interface CustomElementRenderProps {
  onClose?: () => void;
  isOpen?: boolean;
}

export interface CustomElement {
  sideMenu?: React.ReactNode;
  dropdownMenu?: React.ReactNode;
  render?: ({ onClose, isOpen }: CustomElementRenderProps) => React.ReactNode;
}

export type NavItemOptions = {
  desktopAlign?: DesktopAlign;
  mobileMenu?: MobileMenuType;
  type?: ItemTypes;
  dropdownAlignment?: Placement;
  hideOnDesktop?: boolean;
};

export interface NavbarItemProps {
  title: string;
  icon?: string;
  link?: string;
  isActive?: boolean;
  dropdownItems?: DropdownItem[];
  megaMenuColumns?: MegaMenuColumn[];
  activeSubItem?: string;
  topBarStyling?: boolean;
  isOpen?: boolean;
  setIsOpen?: (boolean) => void;
  onClick?: (string, event) => void;
  customElement?: CustomElement;
  testSelector?: string;
  options: NavItemOptions;
  isMobile?: boolean;
}

const defaultOptions = {
  desktopAlign: DesktopAlign.Left,
  mobileMenu: MobileMenuType.Main,
  type: ItemTypes.Button,
  dropdownAlignment: Placement.bottomEnd,
};

const NavbarItem: React.FC<NavbarItemProps> = (props) => {
  const { options, setIsOpen, isOpen, topBarStyling = false, title, dropdownItems, customElement, isMobile } = props;
  const anchorElement = useRef();
  const optionsWithDefault = { ...defaultOptions, ...options };
  const isDropdown = optionsWithDefault.type == ItemTypes.Dropdown || optionsWithDefault.type == ItemTypes.MegaMenu;
  const [openDropdownMenu, setDropdownMenuOpen] = useState(false);
  const openMenu: boolean = isOpen !== undefined && !isMobile ? isOpen : openDropdownMenu;
  const setOpenMenu: (boolean) => void = setIsOpen && !isMobile ? setIsOpen : setDropdownMenuOpen;

  return (
    <>
      {topBarStyling ? (
        <DesktopNavbarItem
          {...props}
          optionsWithDefault={optionsWithDefault}
          isDropdown={isDropdown}
          setOpenMenu={setOpenMenu}
          openMenu={openMenu}
          openDropdownMenu={openDropdownMenu}
          anchorElement={anchorElement}
          key={`desktop-nav-item-${title}`}
          isMobile={isMobile}
        />
      ) : (
        <MobileNavbarItem
          {...props}
          isDropdown={isDropdown}
          anchorElement={anchorElement}
          key={`mobile-nav-item-${title}`}
        />
      )}
      {isMobile && optionsWithDefault.mobileMenu === MobileMenuType.Own && (
        <MobileOwnMenu
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          title={title}
          dropdownItems={dropdownItems}
          customElement={customElement}
          key={`mobile-own-menu-${title}`}
        />
      )}
    </>
  );
};

export default NavbarItem;
