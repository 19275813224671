import React, { useState } from "react";
import { DropdownItem, NavbarItemProps } from "..";
import { Icon, Icons } from "../../../../elements/Icon";
import { MegaMenuColumn } from "../../MegaMenu";
import { styles } from "../NavbarItem.styles";
import Collapsible from "../../../../elements/Collapsible/Collapsible";

type MobileNavbarItemProps = {
  isDropdown: boolean;
  anchorElement: React.MutableRefObject<any>;
};

export const MobileNavbarItem: React.FC<MobileNavbarItemProps & NavbarItemProps> = ({
  testSelector,
  isActive,
  isDropdown,
  link,
  title,
  dropdownItems,
  megaMenuColumns,
  anchorElement,
}) => {
  const [showSubmenuInSideMenu, setShowSubmenuInSideMenu] = useState(false);

  return (
    <>
      <a
        data-testid={testSelector}
        className={isActive ? "active" : ""}
        ref={anchorElement}
        onClick={() => setShowSubmenuInSideMenu(!showSubmenuInSideMenu)}
        href={link}
      >
        <div ref={anchorElement} css={styles.menuItemWrapper}>
          {isDropdown && <Icon icon={Icons.arrowThin} css={styles.arrowSideMenuIcon(showSubmenuInSideMenu)} />}
          {title}
        </div>
      </a>
      {isDropdown && (
        <Collapsible show={showSubmenuInSideMenu}>
          <ul key={`subitem-menu-container-${title}`} css={styles.menuItemWrapper}>
            {dropdownItems &&
              dropdownItems.map((subItem: DropdownItem) => (
                <li key={`subitem-side-menu-${subItem.label}`} css={styles.sideSubmenu}>
                  <a href={subItem.link}>{subItem.label}</a>
                </li>
              ))}
            {megaMenuColumns &&
              megaMenuColumns.map((column: MegaMenuColumn) => {
                return (
                  <div key={`subitem-side-megamenu-${column.title}`}>
                    {column?.title && <div css={styles.columnTitle}>{column.title}</div>}
                    {column.items.map((item) => {
                      return (
                        <li key={`subitem-side-megamenu-item-${item.name}`} css={styles.sideSubmenu}>
                          <a
                            href={item?.link || ""}
                            data-testid={item?.testSelector ? `${item.testSelector}-mobile` : null}
                            className={item?.isActive ? "active" : ""}
                          >
                            {item.icon && <Icon css={styles.itemIcon} icon={item.icon} />}
                            <div>{item.name}</div>
                          </a>
                        </li>
                      );
                    })}
                  </div>
                );
              })}
          </ul>
        </Collapsible>
      )}
    </>
  );
};
