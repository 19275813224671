import React, { useState } from "react";
import { styles } from "./SideMenuButton.styles";
import { Icons, Icon } from "../../../elements/Icon";
import DrawerWrapper from "../DrawerWrapper";
import { DrawerOrientation } from "../DrawerWrapper/DrawerWrapper";
import { NavbarItemSideMenu } from "../NavbarDecorators";
import { DesktopAlign } from "../NavbarItem/NavbarItem";
import { NavbarChild } from "../Navbar";

const SideMenuButton: React.FC<{ children: NavbarChild[] }> = ({ children }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickMenuButton = () => setDrawerOpen(!drawerOpen);

  const sortMenu = (desktopPosition: DesktopAlign) =>
    children
      .filter(
        (child: NavbarChild) =>
          child?.props?.options?.desktopAlign && child.props.options.desktopAlign === desktopPosition
      )
      .map((child: React.ReactElement, index) => {
        return (
          <NavbarItemSideMenu
            key={`drawer-sidebar-${child.props.title}`}
            name={child.props.title}
            isBordered={!(desktopPosition == DesktopAlign.Left && index == 0)}
          >
            {child}
          </NavbarItemSideMenu>
        );
      });

  return (
    <>
      <div onClick={handleClickMenuButton}>
        <Icon icon={Icons.menu} css={styles.iconsStyle} />
      </div>
      <DrawerWrapper
        open={drawerOpen}
        menuTitle={"Menu"}
        onClose={handleClickMenuButton}
        orientation={DrawerOrientation.left}
      >
        {Object.values(DesktopAlign).map((alignment) => {
          return (
            <div key={`main-drawer-menu-container-${alignment}`} css={styles.leftItemContainer}>
              {sortMenu(alignment)}
            </div>
          );
        })}
      </DrawerWrapper>
    </>
  );
};

export default SideMenuButton;
